<template>
  <div>
    <navbar-top left="back"
                middle="counter"
                @cl="back">
      <p class="text-xl text-white">{{ currentCount }}/{{ cardsTotal }}</p>
    </navbar-top>

    <div class="fixed inset-0 py-14 overflow-y-auto"
         style="-webkit-overflow-scrolling: touch;"
         v-touch:swipe.top="() => rotate()"
         v-touch:swipe.right="() => recalled()"
         v-touch:swipe.left="() => forgot()">
      <div v-if="dataLoaded"
           class="wrapper p-3">
        <div v-if="!!flash" class="mx-auto mb-6">
          <flash-side side="question" :blocks="flash.question" />
          <hr class="my-4">
          <flash-side v-if="currentStep !== 'recalling'"
                      side="answer"
                      :blocks="flash.answer" />
        </div>
        <div v-else class="flex flex-col text-center items-center justify-center mx-auto">
          <h1 class="pt-8 text-2xl font-semibold">Все карточки в наборе решены!</h1>
          <div v-if="isDose">
            <p class="pb-2 font-light">Чтобы взяться за следующую порцию карточек, нажми на кнопку:</p>
            <button class="my-2 px-4 py-2 bg-blue-700 rounded text-2xl text-white font-light"
                    @click="$router.go(0)">
              Решить еще!</button>
          </div>
          <div v-else>
            <p class="pb-2 font-light">Чтобы перейти к просмотру всех карточек из данного набора,
              нажми на кнопку:</p>
            <button class="my-2 px-4 py-2 bg-blue-700 rounded text-2xl text-white font-light"
                    @click="toView">
              Все карточки</button>
          </div>
        </div>
      </div>
      <menu-spinner v-else></menu-spinner>
    </div>

    <navbar-bottom :state="currentStep"
                   @rotate="rotate"
                   @check="check"
                   @forgot="forgot"
                   @recalled="recalled" />


    <keypress key-event="keyup" :multiple-keys="keyboard.rotate" @success="rotate" />
    <keypress key-event="keyup" :multiple-keys="keyboard.check" @success="check" />
    <keypress key-event="keyup" :multiple-keys="keyboard.forgot" @success="forgot" />
    <keypress key-event="keyup" :multiple-keys="keyboard.recalled" @success="recalled" />
    <keypress key-event="keyup" :multiple-keys="keyboard.back" @success="back" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Keypress from 'vue-keypress';
import NavbarTop from '@/components/NavbarTop';
import NavbarBottom from '@/components/NavbarBottom';
import MenuSpinner from '@/components/MenuSpinner.vue';
import FlashSide from '@/components/FlashSide.vue';

export default {
  components: { NavbarTop, NavbarBottom, MenuSpinner, FlashSide, Keypress },
  props: {
    deckIds: {
      type: String,
    },
    onlyDue: {
      type: String,
      default: 'true',
    },
  },
  data () {
    return {
      dataLoaded: false,
      lastClickAt: new Date(),
      keyboard: {
        rotate: [
          { keyCode: 13, modifiers: [], preventDefault: false }, // ENTER
          { keyCode: 32, modifiers: [], preventDefault: false }, // SPACE
          { keyCode: 39, modifiers: [], preventDefault: false }, // ARROW RIGHT
        ],
        check: [
          { keyCode: 13, modifiers: [], preventDefault: false }, // ENTER
        ],
        recalled: [
          { keyCode: 13, modifiers: [], preventDefault: false }, // ENTER
          { keyCode: 32, modifiers: [], preventDefault: false }, // SPACE
          { keyCode: 39, modifiers: [], preventDefault: false }, // ARROW RIGHT
        ],
        forgot: [
          { keyCode: 37, modifiers: [], preventDefault: false }, // ARROW LEFT
        ],
        back: [
          { keyCode: 27, modifiers: [], preventDefault: false }, // ESC
        ],
      },
    };
  },
  computed: {
    ...mapState({
      cardsTotal: state => state.flashes.flashesTotal,
      currentCount: state => state.flashes.flashNum,
      flash: state => state.flashes.flash,
      currentStep: state => state.flashes.currentStep,
      testFailed: state => state.flashes.testFailed,
    }),
    isDose () { return !this.$router.currentRoute.params.deckIds; }
  },
  created () {
    this.$store.commit('flashes/initStore');
    this.$store.dispatch('flashes/getFlashes',
        { deckIds: this.deckIds, onlyDue: this.onlyDue })
      .then(() => this.dataLoaded = true);
  },
  methods: {
    rotate () {
      const now = new Date();
      if (now - this.lastClickAt < 250) return false;

      if (this.currentStep === 'recalling') {
        this.lastClickAt = now;
        return this.$store.dispatch('flashes/rotateCard');
      }

      return false;
    },
    check () {
      const now = new Date();
      if (now - this.lastClickAt < 250) return false;

      if (this.currentStep === 'testing') {
        this.lastClickAt = now;
        return this.$store.dispatch('flashes/checkCard');
      }

      return false;
    },
    recalled () {
      const now = new Date();
      if (now - this.lastClickAt < 250) return false;

      if (this.currentStep === 'summarizing' && !this.testFailed) {
        this.lastClickAt = now;
        return this.$store.dispatch('flashes/nextCard', true);
      }

      return false;
    },
    forgot () {
      const now = new Date();
      if (now - this.lastClickAt < 250) return false;

      if (this.currentStep === 'summarizing') {
        this.lastClickAt = now;
        return this.$store.dispatch('flashes/nextCard', false);
      }

      return false;
    },
    toView () { window.location.replace(`/solve/${this.deckIds}?onlyDue=false`); },
    back () { this.$router.push('/'); },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  display: block;
  margin: auto;

  @media (max-width: 639px) {
    width: 100%;
  }

  @media (min-width: 640px) {
    width: 75%;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }
}
</style>
